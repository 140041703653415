/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
font-family: 'Poppins Regular';
font-style: normal;
font-weight: normal;
src: local('Poppins Regular'), url('Poppins-Regular.woff') format('woff');
}


@font-face {
font-family: 'Poppins Italic';
font-style: normal;
font-weight: normal;
src: local('Poppins Italic'), url('Poppins-Italic.woff') format('woff');
}


@font-face {
font-family: 'Poppins Thin';
font-style: normal;
font-weight: normal;
src: local('Poppins Thin'), url('Poppins-Thin.woff') format('woff');
}


@font-face {
font-family: 'Poppins Thin Italic';
font-style: normal;
font-weight: normal;
src: local('Poppins Thin Italic'), url('Poppins-ThinItalic.woff') format('woff');
}


@font-face {
font-family: 'Poppins ExtraLight';
font-style: normal;
font-weight: normal;
src: local('Poppins ExtraLight'), url('Poppins-ExtraLight.woff') format('woff');
}


@font-face {
font-family: 'Poppins ExtraLight Italic';
font-style: normal;
font-weight: normal;
src: local('Poppins ExtraLight Italic'), url('Poppins-ExtraLightItalic.woff') format('woff');
}


@font-face {
font-family: 'Poppins Light';
font-style: normal;
font-weight: normal;
src: local('Poppins Light'), url('Poppins-Light.woff') format('woff');
}


@font-face {
font-family: 'Poppins Light Italic';
font-style: normal;
font-weight: normal;
src: local('Poppins Light Italic'), url('Poppins-LightItalic.woff') format('woff');
}


@font-face {
font-family: 'Poppins Medium';
font-style: normal;
font-weight: normal;
src: local('Poppins Medium'), url('Poppins-Medium.woff') format('woff');
}


@font-face {
font-family: 'Poppins Medium Italic';
font-style: normal;
font-weight: normal;
src: local('Poppins Medium Italic'), url('Poppins-MediumItalic.woff') format('woff');
}


@font-face {
font-family: 'Poppins SemiBold';
font-style: normal;
font-weight: normal;
src: local('Poppins SemiBold'), url('Poppins-SemiBold.woff') format('woff');
}


@font-face {
font-family: 'Poppins SemiBold Italic';
font-style: normal;
font-weight: normal;
src: local('Poppins SemiBold Italic'), url('Poppins-SemiBoldItalic.woff') format('woff');
}


@font-face {
font-family: 'Poppins Bold';
font-style: normal;
font-weight: normal;
src: local('Poppins Bold'), url('Poppins-Bold.woff') format('woff');
}


@font-face {
font-family: 'Poppins Bold Italic';
font-style: normal;
font-weight: normal;
src: local('Poppins Bold Italic'), url('Poppins-BoldItalic.woff') format('woff');
}


@font-face {
font-family: 'Poppins ExtraBold';
font-style: normal;
font-weight: normal;
src: local('Poppins ExtraBold'), url('Poppins-ExtraBold.woff') format('woff');
}


@font-face {
font-family: 'Poppins ExtraBold Italic';
font-style: normal;
font-weight: normal;
src: local('Poppins ExtraBold Italic'), url('Poppins-ExtraBoldItalic.woff') format('woff');
}


@font-face {
font-family: 'Poppins Black';
font-style: normal;
font-weight: normal;
src: local('Poppins Black'), url('Poppins-Black.woff') format('woff');
}


@font-face {
font-family: 'Poppins Black Italic';
font-style: normal;
font-weight: normal;
src: local('Poppins Black Italic'), url('Poppins-BlackItalic.woff') format('woff');
}