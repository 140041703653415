/*!

=========================================================
* Material Dashboard Angular - v2.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-angular2
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-angular2/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
.tim-typo {
  padding-left: 25%;
  margin-bottom: 40px;
  position: relative;
  width: 100%;
}
.tim-typo .tim-note {
  bottom: 5px;
  color: #c0c1c2;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  left: 0;
  margin-left: 20px;
  position: absolute;
  width: 260px;
}
.sidebar .logo-img {
  max-height: 100%;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}
.sidebar .logo-img img {
  width: 100%;
  top: unset;
  position: static;
  max-width: 60%;
}
.form-control {
  height: 50px;
}
.admin-login-wrap {
  height: 100vh;
  display: flex;
  align-items: center;
}

.custom_field.mat-form-field-appearance-fill .mat-form-field-flex{
  background-color: transparent;
}