@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?agaxy8');
  src:  url('fonts/icomoon.eot?agaxy8#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?agaxy8') format('truetype'),
    url('fonts/icomoon.woff?agaxy8') format('woff'),
    url('fonts/icomoon.svg?agaxy8#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-product-1:before {
  content: "\e913";
}
.icon-product-box:before {
  content: "\e914";
}
.icon-product-hand:before {
  content: "\e915";
}
.icon-twitter:before {
  content: "\e90f";
}
.icon-facebook-logo:before {
  content: "\e910";
}
.icon-email:before {
  content: "\e911";
}
.icon-pinterest:before {
  content: "\e912";
}
.icon-information:before {
  content: "\e90e";
}
.icon-packing:before {
  content: "\e90d";
}
.icon-search:before {
  content: "\e902";
}
.icon-User:before {
  content: "\e900";
}
.icon-hamburger-menu:before {
  content: "\e901";
}
.icon-bell:before {
  content: "\e903";
}
.icon-order:before {
  content: "\e904";
}
.icon-product:before {
  content: "\e905";
}
.icon-catalog:before {
  content: "\e906";
}
.icon-menu:before {
  content: "\e907";
}
.icon-support:before {
  content: "\e908";
}
.icon-contract:before {
  content: "\e909";
}
.icon-app-1:before {
  content: "\e90a";
}
.icon-shutdown:before {
  content: "\e90b";
}
.icon-chat:before {
  content: "\e90c";
}
